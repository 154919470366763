


























import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class SimpleTable extends Vue {
  @Prop({ required: true }) readonly headers!: string[];
  @Prop({ required: true }) readonly rows!: [];

  @Prop({ default: "" }) readonly modClass!: string;
}

export default SimpleTable;
