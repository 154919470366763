















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
class Notice extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop({ default: "success" }) readonly type!: string;

  showNotice = false;

  get noticeType(): string {
    const availableTypes = ["error", "success", "warning"];

    return availableTypes.includes(this.type) ? this.type : "success";
  }

  get noticeContentClassName(): string {
    return `${this.noticeType} box box_shadow_initial`;
  }

  get noticeIcon(): string {
    return this.noticeType === "success"
      ? "mdi-check-circle"
      : "mdi-alert-circle";
  }

  @Watch("show")
  showChanged() {
    this.showNotice = true;

    setTimeout(() => (this.showNotice = false), 5000);
  }
}

export default Notice;
