












import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class ContractIcon extends Vue {
  @Prop({ required: true }) readonly contractType!: string;
  @Prop({ default: "lg" }) readonly size!: string;

  iconProps = [
    {
      mdi: "mdi-home",
      color: "green",
    },
    {
      mdi: "mdi-weather-sunny",
      color: "yellow",
    },
    {
      mdi: "mdi-lightbulb",
      color: "blue",
    },
    {
      mdi: "mdi-delete",
      color: "green",
    },
  ];

  get icon() {
    return this.iconProps[parseInt(this.contractType) - 1];
  }
}

export default ContractIcon;
