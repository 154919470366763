








































































































































import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapMutations, mapState } from "vuex";
import { RawLocation } from "vue-router";
import Enviroment from "@/models/enviroment";

type SidebarItem = {
  mdi: string;
  text: string;
  to: RawLocation;
  use?: boolean;
};

type Divider = { isDivider: boolean; use?: boolean };

@Component({
  computed: {
    ...mapState({
      appEnviroment: "appEnviroment",
      sidebar: "sidebar",
    }),
    ...mapGetters("contract", {
      useContract: "useContract",
      contractId: "id",
      contractType: "type",
    }),
    ...mapGetters("organization", { organizationId: "id" }),
    ...mapGetters("user", { passwordChangeRequired: "passwordChangeRequired" }),
  },
  methods: { ...mapMutations({ setIsMini: "sidebar/setIsMini" }) },
})
class Sidebar extends Vue {
  [x: string]: any;

  lastItem = {
    mdi: "mdi-help-circle",
    text: "Помощь",
    onClick: () => {
      const appHelpRef = process.env && process.env.VUE_APP_HELP_REF;
      appHelpRef && window.open(appHelpRef, "_blank");
    },
  };

  public get isMini(): boolean {
    return this.sidebar.isMini;
  }

  public get enviroment(): Enviroment | null {
    return this.appEnviroment.constants;
  }

  public get items(): (SidebarItem | Divider)[] {
    return [
      {
        mdi: "mdi-script-text",
        text: "Мои договоры",
        to: { name: "myContracts" },
      },
      {
        mdi: "mdi-office-building",
        text: "Карточка организации",
        to: {
          name: "companyCard",
          params: { companyId: this.organizationId },
        },
      },
      {
        mdi: "mdi-card-bulleted",
        text: "Карточка договора",
        to: {
          name: "contractCard",
          params: { contractId: this.contractId },
        },
      },
      { isDivider: true },
      {
        mdi: "mdi-counter",
        text: "Показания",
        to: { name: "countersValues" },
      },
      {
        use: !!(this.enviroment && this.enviroment["ЛКЮЛОПЛАТА"]),
        mdi: "mdi-credit-card-outline",
        text: "Оплата",
        to: { name: "payments" },
      },
      {
        use:
          !!(this.enviroment && this.enviroment["ЛКЮЛПОЧАС"]) &&
          this.contractType === 3,
        mdi: "mdi-clock-time-nine",
        text: "Почасовое потребление",
        to: {
          name: "hourlyConsumption",
          params: { contractId: this.contractId },
        },
      },
      {
        mdi: "mdi-calendar-month",
        text: "Помесячный план",
        to: { name: "monthlyPlan" },
      },
      { isDivider: true },
      {
        mdi: "mdi-file-document",
        text: "Документы",
        to: {
          name: "documents",
          params: { contractId: this.contractId },
        },
      },
      {
        mdi: "mdi-file-chart",
        text: "Обороты и отчеты",
        to: {
          name: "turnoversAndReports",
          params: { companyId: this.organizationId },
        },
      },
      { isDivider: true },
      {
        mdi: "mdi-comment-text-multiple",
        text: "Обращения",
        to: { name: "appeals" },
      },
    ];
  }

  public get showedItems(): (SidebarItem | Divider)[] {
    const { items, useContract, passwordChangeRequired } = this;

    if (passwordChangeRequired) return [];

    if (!useContract) return [items[0]];

    return items
      .map(({ use = true, ...item }) => ({ use, ...item }))
      .filter(({ use }) => use);
  }
}

export default Sidebar;
