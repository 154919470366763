




























import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import XDialog from "../SimpleDialog.vue";
import AppApiMixin from "../mixins/AppApi.vue";

@Component({
  components: { XDialog },
})
class ContractRemovingDialog extends Mixins(AppApiMixin, XDialog) {
  [x: string]: any;

  @Prop({ required: true }) readonly showDialog!: boolean;
  @Prop({ required: true }) readonly contractId!: number;

  useAgreeButtonLoading = false;
  submitErrorText = "";

  @Watch("showDialog")
  public showDialogChanged() {
    this.submitErrorText = "";
  }

  public onAgree(evt: { name: string; payload: null }) {
    this.submitErrorText = "";
    this.useAgreeButtonLoading = true;

    this.removeContract(this.contractId, () => {
      this.propagateCustomEvt(evt.name);
    })
      .catch((_) => (this.submitErrorText = "Непредвиденная ошибка"))
      .finally(() => (this.useAgreeButtonLoading = false));
  }
}

export default ContractRemovingDialog;
