
































import { Component, Prop, Vue } from "vue-property-decorator";
import SimpleCard from "./SimpleCard.vue";
import SimpleTable from "./SimpleTable.vue";

@Component({
  components: { SimpleCard, SimpleTable },
})
class SimpleCardWithTable extends Vue {
  // SimpleTable props
  @Prop({ required: true }) readonly headers!: string[];
  @Prop({ required: true }) readonly rows!: [];
  @Prop({ default: "" }) readonly tableModClass!: string;
  @Prop({ default: "" }) readonly tableHeader!: string;
}

export default SimpleCardWithTable;
