/// <reference path="typings.d.ts" />

import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import { store } from "./store/store";
import vuetify from "./plugins/vuetify";
import VueMask from "v-mask";
import "./styles/index.scss";

Vue.config.productionTip = false;

Vue.use(VueMask);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
