

















import { Component, Vue, Watch } from "vue-property-decorator";
import { mapState } from "vuex";
import AppBar from "@/components/for-page-grid/AppBar.vue";
import Sidebar from "@/components/for-page-grid/Sidebar.vue";

@Component({
  components: { AppBar, Sidebar },
  computed: { ...mapState({ auth: "auth", dialog: "dialog" }) },
})
class App extends Vue {
  [x: string]: any;

  /*
    Использовать свойство topCssProp при работе со всплывающими элементами страницы,
    такими как, поупап или слайдер, с целью контроля текущих координат пользователя
    по оси Y, что позволяет "сохранить" позицию пользователя при закртыии указанных
    элементов
  */
  topCssProp = { value: 0, valuePx: "" };

  public get loggedIn(): boolean {
    return this.auth.loggedIn;
  }

  public get isOpenDialog(): boolean {
    return this.dialog.isOpen;
  }

  @Watch("loggedIn")
  public loggedInChanged() {
    if (!this.loggedIn) {
      this.$router.push({ name: "auth" });
    }
  }

  @Watch("isOpenDialog")
  public isOpenDialogChanged() {
    if (this.dialog.isOpen) {
      const top = window.pageYOffset;

      this.topCssProp.value = top;
      this.topCssProp.valuePx = top ? `-${top}px` : "";
    }
  }

  updated() {
    const top = this.topCssProp.value;
    window.scroll({ top });
  }
}

export default App;
