






























import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

@Component
class XTabs extends Vue {
  @Prop({ required: true }) readonly titles!: string[];
  @Prop({ required: true }) readonly components!: [];

  tab = 0;

  isActiveTab(tabIndex: number): boolean {
    return this.tab === tabIndex;
  }

  @Emit()
  public emitUpdateTabEvt() {
    this.$emit("update:tab", this.tab);
  }

  @Watch("tab")
  public tabChanged() {
    this.emitUpdateTabEvt();
  }
}

export default XTabs;
