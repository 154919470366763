import { email, required } from "./field-rules";

export default [
  {
    name: "email",
    attrs: {
      type: "email",
      label: "Email",
      required: true
    },
    rules: [required, email]
  }
];
