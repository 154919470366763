
























import { Component } from "vue-property-decorator";
import XDropdown from "../SimpleDropdown.vue";

@Component({
  components: { XDropdown },
})
class AccountDropdown extends XDropdown {
  itemProps = [
    {
      icon: "mdi-cog",
      title: "Настройки",
      handler: () => {
        const routeName = "accountSettings";
        this.$route.name !== routeName &&
          this.$router.push({ name: routeName });
      },
    },
    {
      icon: "mdi-help-circle",
      title: "Помощь",
      handler: () => {
        const appHelpRef = process.env && process.env.VUE_APP_HELP_REF;
        appHelpRef && window.open(appHelpRef, "_blank");
      },
    },
    { isDivider: true },
    {
      icon: "mdi-logout-variant",
      title: "Выход",
      handler: () => this.$emit("logout"),
    },
  ];
}

export default AccountDropdown;
