import { digit, email, phone, required } from "./field-rules";

export default [
  {
    name: "email",
    attrs: {
      type: "email",
      label: "Email",
      required: true
    },
    rules: [required, email]
  },
  {
    name: "contact",
    attrs: {
      type: "text",
      label: "Контактное лицо",
      required: true
    },
    rules: [required]
  },
  {
    name: "phone",
    attrs: {
      type: "tel",
      label: "Мобильный телефон",
      required: true,
      prefix: "+7"
    },
    rules: [required, phone]
  },
  {
    name: "taxpayerId",
    attrs: {
      type: "text",
      label: "ИНН",
      required: true
    },
    rules: [
      required,
      digit,
      {
        type: "minLength",
        arg: 10,
        error: "Значение поля должно содержать не менее 10 цифр"
      },
      {
        type: "maxLength",
        arg: 12,
        error: "Значение поля должно содержать не более 12 цифр"
      }
    ]
  },
  {
    name: "contractList",
    attrs: {
      type: "textarea",
      label: "Подключаемые договоры",
      required: true
    },
    rules: [required]
  }
];
