






























































import { Component, Mixins, Prop } from "vue-property-decorator";
import { validationMixin } from "vuelidate";
import XForm from "../SimpleForm.vue";
import XButton from "../SimpleButton.vue";
import AppApiMixin from "../mixins/AppApi.vue";
import { countersNewValueForm } from "@/assets/scripts/form-fields/main";
import ConfirmActionDialog from "./ModalDialogForIndication.vue";
import { mapGetters } from "vuex";
import XNotice from "@/components/hoc/SimpleNotice.vue";

const { values, attrs, validations, errors } = countersNewValueForm;

@Component({
  components: {
    XForm,
    XButton,
    XNotice,
    ConfirmActionDialog
  },
  mixins: [validationMixin],
  computed: {
    ...errors,
    ...mapGetters("appEnviroment", {
      valuesInputDisabled: "valuesInputDisabled"
    })
  },
  validations
})
class CountersNewValueForm extends Mixins(XForm, AppApiMixin) {
  @Prop({ required: true }) readonly rate!: number;
  @Prop({ required: true }) readonly rateFloat!: number;
  @Prop({ required: true }) readonly tarif!: number;
  @Prop({ required: true }) readonly meteringPointId!: number;
  @Prop({ required: true }) readonly counterId!: number;
  @Prop({ required: true }) readonly indication!: number;

  values = { ...values };
  invalidRate = false;
  useSuccessAlert = false;
  successMessage = "Показания успешно переданы на обработку";
  rateFlag = false;

  getMask(str: string) {
    let r = "".padStart(this.rate, str);
    let rf = this.rateFloat > 0 ? "." + "".padStart(this.rateFloat, str) : "";
    let mask = "" + r + rf;
    return mask;
  }

  get rateValidator() {
    const { rate, rateFloat } = this;

    const pattern = {
      template: `^\\d{1,${rate}}*$`,
      replacer: rateFloat > 0 ? `([.,]\\d{1,${rateFloat}})?` : ""
    };

    return new RegExp(pattern.template.replace("*", pattern.replacer));
  }

  /**
   * Вычисляемое свойство, которое вычисляет текущий расход.
   *
   * @returns consumption текущий расход - разница между предыдущим и следующим
   * показаниями.
   */
  get currentConsumption(): string {
    const { indication, rate, rateFloat, values } = this;

    const currentIndication = values.indication;
    const isInvalidCurrentIndication =
      !currentIndication.length || Number.isNaN(Number(currentIndication));

    let consumption = 0;

    // Получить расход, как отформатированную строку
    const getFormattedConsumption = (): string =>
      Number.isInteger(consumption)
        ? consumption.toString()
        : consumption.toFixed(rateFloat);

    /*
     * Описание условного ветвления:
     *
     * 1. Если текущее показание невалидное значение;
     * 2. Если текущее показание меньше предыдущего;
     * */
    if (isInvalidCurrentIndication) {
      return getFormattedConsumption();
    }

    consumption = currentIndication - indication;

    if (consumption < 0) {
      const maxIndication = [rate, rateFloat]
        .map(it => "9".repeat(it))
        .join(".");

      consumption += parseFloat(maxIndication);
    }

    return getFormattedConsumption();
  }

  public validateRate(readings: string) {
    if (!readings) return true;

    this.rateFlag = false;

    if (!this.rateValidator.test(readings)) {
      this.invalidRate = true;
      return false;
    }

    const readingsAsNumber = +readings;

    if (this.indication > readingsAsNumber && readingsAsNumber > 0) {
      this.rateFlag = true;
    }

    this.invalidRate = false;
    this.submitError = "";

    return true;
  }

  allFieldAttrs = attrs;

  useSubmitButtonLoading = false;
  submitError = "";

  show = false;

  public closeAllDialogs() {
    this.show = false;
    this.useSubmitButtonLoading = false;
  }

  showSuccessInfo() {
    this.useSuccessAlert = true;
  }

  submitForm() {
    this.submitError = "";
    this.useSubmitButtonLoading = true;

    if (this.rateFlag || parseFloat(this.currentConsumption) === 0) {
      this.show = true;
    } else {
      this.show = false;
      this.sendData();
    }
  }

  sendData() {
    const { indication, values } = this;
    const isTwisting = parseFloat(values.indication) < indication;

    this.sendCountersNewValue(
      this.meteringPointId,
      this.counterId,
      this.tarif,
      +values.indication,
      isTwisting ? 1 : 0
    )
      .then(() => {
        this.showSuccessInfo();
      })
      .catch(() => {
        this.closeAllDialogs();
      })
      .finally(() => {
        this.useSubmitButtonLoading = false;
      });
  }
}

export default CountersNewValueForm;
