import { Module, Mutation, VuexModule } from "vuex-module-decorators";

@Module({ namespaced: true })
class ErrorModule extends VuexModule {
  show = false;
  message: string | null = null;

  public get currentMessage(): string {
    return this.message ? this.message : "";
  }

  @Mutation
  setShow(show: boolean) {
    this.show = show;
  }

  @Mutation
  setMessage(message: string | null) {
    this.message = message;
  }
}

export default ErrorModule;
