

































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { mapGetters } from "vuex";
import { formatDate } from "@/lib/date";
import { prepareMoney } from "@/assets/scripts/utils";
import { ContractBalance, ContractsByOrganization } from "@/models/contract";
import XDataTable from "../hoc/Table.vue";
import ContractIcon from "../ContractIcon.vue";
import XButton from "../SimpleButton.vue";

type TableRow = Array<(number | string)[] | string>;

@Component({
  components: {
    XDataTable,
    ContractIcon,
    XButton,
  },
  computed: {
    ...mapGetters({ currentContractId: "contract/id" }),
  },
  filters: {
    formatDate(value: string): string {
      return formatDate(value, "full");
    },
    prepareMoney(value: number): string {
      return prepareMoney(value);
    },
  },
})
class ContractTable extends Vue {
  [x: string]: any;

  @Prop({ default: () => [] }) readonly contracts!: ContractsByOrganization;
  @Prop({ default: () => [] }) readonly balances!: ContractBalance[];

  headers = [
    { text: "Договор", value: "type", width: "25%" },
    { text: "Срок действия", value: "term" },
    { text: "Расчетчик", value: "contact1", width: "15%" },
    { text: "Договорник", value: "contact2", width: "15%" },
    {
      text: "Сумма к оплате",
      value: "amountToPay",
      width: "25%",
      align: "end",
    },
    { text: "", value: "action" },
  ];

  /**
   * Возвращает массив, элементами которого являются массивы, каждый из которых
   * представляет отдельную строку таблицы. Массив - строка таблицы, также состоит
   * из массивов, каждый из которых представляет отдельную ячейку таблицы.
   * @returns {Array<TableRow>}
   */
  public get preparedContracts(): TableRow[] {
    const { contractList } = this.contracts;

    const arrayOfContractKeysByTd = [
      ["видпотребления", "номер", "тема"],
      ["начало договора", "окончание"],
      ["расчетникфио", "расчетниктелефон"],
      ["договорниктелефон", "договорникфио"],
      ["$номерЗаписи"],
    ];

    return contractList.map((contract) => {
      const arrayOfDataByTd = arrayOfContractKeysByTd.map((contractKeysByTd) =>
        contractKeysByTd.map((contractKey) => {
          const contractValue = contract[contractKey];

          if (contractKey === "$номерЗаписи") {
            const contractBalance = this.balances.find(
              (balance) => balance["договор"] === contractValue
            );

            return (this.currentContractSaldo = contractBalance
              ? contractBalance["сальдо"]
              : "");
          }

          return contractValue;
        })
      );
      return [...arrayOfDataByTd, ""];
    });
  }

  public get companyName(): string {
    return this.contracts.name;
  }

  public get commonCompanyInfo() {
    const { taxpayerId, taxpayerCode, address } = this.contracts;

    return [
      {
        mdi: "mdi-clipboard-list",
        text: `ИНН ${taxpayerId} / КПП ${taxpayerCode}`,
      },
      {
        mdi: "mdi-map-marker",
        text: address,
      },
    ];
  }

  public isCurrentContract(index: number): boolean {
    const contractIdFromList =
      this.contracts.contractList[index]["$номерЗаписи"];

    return contractIdFromList === this.currentContractId;
  }
}

export default ContractTable;
