import * as vuelidateValidators from "vuelidate/lib/validators";
import { Field } from "@/models/form";
interface SimpleObject {
  [x: string]: any;
}

interface FieldProps {
  values: SimpleObject;
  attrs: SimpleObject;
  validations: SimpleObject;
  errors: SimpleObject;
}

// [Begin]
const validators: SimpleObject = vuelidateValidators;

const getFields = (): FieldProps => ({
  values: {},
  attrs: {},
  validations: { values: {} },
  errors: {}
});

const getEmptyArray = (): string[] => [];

// Основной обработчик
function prepareFieldsForComponent(fields: Field[]): FieldProps {
  return fields.reduce((acc, field) => {
    const { name, attrs, rules = null } = field;

    acc.values[name] = attrs.default || "";
    acc.attrs[name] = attrs;

    if (rules) {
      const validationList: SimpleObject = {};
      acc.validations.values[name] = rules.reduce((validations, rule) => {
        const { type, arg = null, validator = null } = rule;

        if (validator) {
          validations[type] = validator;
        } else {
          validations[type] = arg ? validators[type](arg) : validators[type];
        }

        return validations;
      }, validationList);

      acc.errors[`${name}Errors`] = function() {
        if (!this.$v.values[name].$dirty) return [];
        //@ts-ignore
        return rules.reduce((errors, rule) => {
          const { type, error } = rule;
          !this.$v.values[name][type] && errors.push(error);
          return errors;
        }, getEmptyArray());
      };
    }

    return acc;
  }, getFields());
}

export { prepareFieldsForComponent };
// [End]
