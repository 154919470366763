




























import { Component, Vue } from "vue-property-decorator";
import XCard from "../SimpleCard.vue";
import CardRowWithEmailForm from "./CardRowWithEmailForm.vue";
import CardRowWithPasswordForm from "./CardRowWithPasswordForm.vue";

@Component({
  components: {
    XCard,
    CardRowWithEmailForm,
    CardRowWithPasswordForm,
  },
})
class ReplacementCard extends Vue {
  showEmail = false;
  showPassword = false;
}

export default ReplacementCard;
