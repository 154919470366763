var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('x-data-table',{staticClass:"data-table_type_contracts",attrs:{"caption":_vm.companyName,"headers":_vm.headers,"items":_vm.preparedContracts,"use-item-slot":true,"hide-default-footer":true},scopedSlots:_vm._u([{key:"afterCaption",fn:function(){return _vm._l((_vm.commonCompanyInfo),function(ref,i){
var mdi = ref.mdi;
var text = ref.text;
return _c('span',{key:i},[_c('v-icon',[_vm._v(_vm._s(mdi))]),_vm._v(" "+_vm._s(text)+" ")],1)})},proxy:true},{key:"item",fn:function(ref){
var ref_item = ref.item;
var index = ref_item.index;
var item = ref_item.item;
return [_c('tr',{staticClass:"data-table__text",class:{
        'data-table__text_active_contract': _vm.isCurrentContract(index),
      },on:{"click":function($event){return _vm.$emit('choose', _vm.contracts.contractList[index])}}},_vm._l((item),function(tdContent,header){return _c('td',{key:header,class:[['amountToPay', 'action'].includes(header) && 'text-right']},[(header.startsWith('type'))?_c('div',{staticClass:"data-table__td-content align-center"},[_c('contract-icon',{staticClass:"mr-4",attrs:{"contractType":tdContent[0]}}),_c('span',{staticClass:"d-block text-body-1"},[_vm._v(_vm._s(tdContent[1]))]),_c('span',{staticClass:"d-block secondary--text text--lighten-1"},[_vm._v(" "+_vm._s(tdContent[2])+" ")])],1):(header.startsWith('term'))?[_c('span',{staticClass:"d-block text-body-1"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(tdContent[0]))+" ")]),_c('span',{staticClass:"d-block secondary--text text--lighten-1"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(tdContent[1]))+" ")])]:(header.startsWith('contact'))?[_c('span',{staticClass:"d-block text-body-1"},[_vm._v(_vm._s(tdContent[0]))]),_c('a',{staticClass:"d-block primary--text text-body-1 text-decoration-none",attrs:{"href":("tel: " + (tdContent[1]))},on:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(tdContent[1])+" ")])]:(header.endsWith('Pay'))?[(tdContent[0] === '')?_c('v-progress-circular',{staticClass:"ma-auto",attrs:{"color":"primary","indeterminate":"","size":24,"width":2}}):(tdContent[0] <= 0)?_c('span',{staticClass:"font-weight-regular text-h6"},[_vm._v(" "+_vm._s(_vm._f("prepareMoney")(0))+" ₽ ")]):_c('span',{class:[
              'data-table__saldo',
              'data-table__saldo_error',
              'box',
              'box_width_auto',
              'd-inline-flex',
              'align-center',
              'px-2',
              'py-1',
              'rounded' ]},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v("mdi-alert-circle")]),_c('span',{staticClass:"error--text font-weight-regular text-h6"},[_vm._v(" "+_vm._s(_vm._f("prepareMoney")(tdContent[0]))+" ₽ ")])],1)]:_c('x-button',{attrs:{"color":"background","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('remove', _vm.contracts.contractList[index]['$номерЗаписи'])}}},[_c('v-icon',{staticClass:"mr-0"},[_vm._v("mdi-close-circle-outline")])],1)],2)}),0)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }