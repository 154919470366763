import { eachMonthOfInterval, format, parseISO } from "date-fns";

enum Months {
  "Январь" = 1,
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь"
}

function formatDate(
  date: string | Date,
  dateFormat: "full" | "monthAndYear" | null = null
): string {
  let parsedDate: Date;

  if (date instanceof Date) {
    parsedDate = date;
  } else {
    try {
      parsedDate = parseISO(date);
    } catch(e) {
      return "";
    }
  }

  const datePatterns = {
    monthAndYear: "LL.yyyy",
    full: "dd.LL.yyyy"
  };

  try {
    return dateFormat && datePatterns[dateFormat]
      ? format(parsedDate, datePatterns[dateFormat])
      : parsedDate.toISOString();
  } catch {
    return ''
  }
}

function getEachMonthOfInterval(start: Date, end: Date): string[] {
  try {
    return eachMonthOfInterval({ start, end }).map(date =>
      formatDate(date, "monthAndYear")
    );
  } catch (e) {
    console.error(e.message);
    return Array(2).fill("");
  }
}

function getMonthName(date: string, useAbbr = false): string {
  const monthIndex = parseInt(formatDate(date, "monthAndYear").split("-")[0]);
  const month = Months[monthIndex];

  return useAbbr ? month.slice(0, 3) : month;
}

function getDateByString(date: string): string {
  return (
    date &&
    date
      .split(".")
      .reverse()
      .join("-")
  );
}

function isDateString(value: any): boolean {
  const formattedDate = getDateByString(`${value}`);
  const parsedDate = Date.parse(formattedDate);

  return !Number.isNaN(parsedDate) && parsedDate > 0;
}

export {
  formatDate,
  getEachMonthOfInterval,
  getMonthName,
  getDateByString,
  isDateString
};
